<template>
  <section class="conOfMissionVission h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'Mission &', part2: 'Vision' }" />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3" v-if="listItems && listItems.length > 0">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="conOfEachItem">
              <div class="titleContainerSec">
                <v-img
                  src="@/assets/img/target.svg"
                  max-width="35"
                  alt="image Partners"
                ></v-img>
                <div class="titleSec">Mission</div>
              </div>
              <div
                class="descriptionContainerSec"
                v-html="listItems[0].mission"
              ></div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="conOfEachItem">
              <div class="titleContainerSec">
                <v-img
                  src="@/assets/img/focus.svg"
                  max-width="35"
                  alt="image Partners"
                ></v-img>
                <div class="titleSec">Vision</div>
              </div>
              <div
                class="descriptionContainerSec"
                v-html="listItems[0].vision"
              ></div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="conOfEachItem">
              <div class="titleContainerSec">
                <v-img
                  src="@/assets/img/strategy.svg"
                  max-width="35"
                  alt="image Partners"
                ></v-img>
                <div class="titleSec">Values</div>
              </div>
              <div class="descriptionContainerSec">
                Patient centric | Connect | Collaborative | Inclusive |
                Professional| Transparent | Innovative|
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="conOfEachItem">
              <div class="titleContainerSec">
                <v-img
                  src="@/assets/img/strategy.svg"
                  max-width="35"
                  alt="image Partners"
                ></v-img>
                <div class="titleSec">strategy</div>
              </div>
              <div
                class="descriptionContainerSec"
                v-html="listItems[0].strategy"
              ></div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="conOfEachItem">
              <div class="titleContainerSec">
                <v-img
                  src="@/assets/img/strategy.svg"
                  max-width="35"
                  alt="image Partners"
                ></v-img>
                <div class="titleSec">Key performance Indicators:</div>
              </div>
              <div class="descriptionContainerSec">
                <template>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Category</th>
                          <th class="text-left">KPI</th>
                          <th class="text-left">Target</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in dataTable" :key="i">
                          <td>{{ item.category }}</td>
                          <td>
                            <p v-for="(kpi, k) in item.kpi" :key="k">
                              {{ kpi }}
                            </p>
                          </td>
                          <td>
                            <p v-for="(target, k) in item.target" :key="k">
                              {{ target }}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "About Us",
        disabled: true,
      },
      {
        text: "Mission & Vision",
        disabled: false,
      },
    ],
    listItems: null,
    isLoading: false,
    dataTable: [
      {
        category: "CPD",
        kpi: [
          "Number of activities",
          "Number of attendees",
          "Attendees Evaluation for the activities",
        ],
        target: ["26", "+10000", "4.5 (out of 5)"],
      },
      {
        category: "Publications",
        kpi: [
          "Number of original paper publications",
          "Number of guidelines/ pathway publications",
        ],
        target: ["2", "2"],
      },
      {
        category: "Community Awareness ",
        kpi: [
          "Number activities",
          "Number attendees",
          "Educational materials ( videos, infographics, books)",
        ],
        target: ["2", "1000", "12"],
      },
      {
        category: "Collaboration",
        kpi: [
          "International collaboration",
          "National collaboration",
          "Governmental collaboration",
        ],
        target: ["2", "2", "2"],
      },
      {
        category: "Membership",
        kpi: ["Active members", "Total members"],
        target: ["429", "1000"],
      },
      {
        category: "Finance",
        kpi: ["Account fund"],
        target: ["Income > Expenses"],
      },
      {
        category: "Social media",
        kpi: [
          "Active accounts",
          "YouTube views",
          "Tweets impression",
          "Instagram impression",
          "Newspaper",
        ],
        target: ["3", "20,000", "300,000", "20,000", "2"],
      },
    ],
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    getListData() {
      this.listItems = [];
      this.isLoading = true;

      apiServices.get("mission-vision-strategy").then((res) => {
        if (res) {
          this.listItems = res.data;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_missionAndVision.scss";
</style>
